import request from '@/libs/http'

export const vipSaveVipUserInfo = params => request.post('/crm/vip/save-vip-user-info', params) //保存会员信息
export const queryDeptPayList = params => request.post('/crm/vip/query-dept-vip-payment-mode-list', params) //查询店铺会员充值支付方式
export const queryRechargeTemp = params => request.post('/crm/vip/query-recharge-template-list', params) //查询充值模板列表
export const createBill = params => request.post('/crm/vip/create-vip-account-recharge-bill', params) //创建充值单据
export const payVipOrding = params => request.post('/crm/vip/generate-vip-payment-info', params) //支付订单
export const cancelPayment = params => request.post('/crm/vip/cancel-payment', params) //撤销支付
export const vipPayConfir = params => request.post('/crm/vip/confirm-payment', params) //线上支付确认支付
export const vipPayConfirState = params => request.post('/crm/vip/query-recharge-bill-payment-state', params) //查询线上支付的支付状态
export const vipRechargeStatistic = params => request.post('/crm/vip/query-vip-recharge-statistic', params) //查询会员充值统计
export const vipAccountRechargeRecode = params => request.post('/crm/vip/query-vip-account-recharge-recode', params) //查询会员充值统计
export const secondValidState = params => request.post('/crm/vip/second-valid-payment-state', params) //继续支付前效验支付通道是否已关闭
export const accountRechargeBill = params => request.post('/crm/vip/cancel-account-recharge-bill', params) //取消支付
export const queryMemberList = params => request.post('/crm/vip/query-vip-info-list', params) //查询会员列表
export const crmVipDetail = params => request.post('/crm/vip/query-vip-detail', params) //查询会员详情
export const crmVipQueryConsume = params => request.post('/pos/posOrderWide/query-order-by-crmId', params) //查询会员消费记录
export const vipAccountBalance = params => request.post('/crm/vip/query-account-balance-detail-list', params) //查询会员余额明细
export const vipAccountIntegral = params => request.post('/crm/vip/query-account-integral-detail-list', params) //查询会员积分明细
export const printQueryVipInfo = params => request.post('/crm/vip/print-query-vip-info', params) //输入搜索会员
export const queryMemberPayType = params => request.post('/crm/vip/query-dept-vip-payment-mode-list-v2', params) //查询会员支付方式

export const crmIntegralList = params => request.post('/crm/integral-goods/query-page-list', params) //会员积分兑换货品列表
export const crmIntegralListGood = params => request.post('/pos/crm-vip/do-integral-exchange-good', params) //会员积分兑换
export const queryExchangeRecordList = params =>
  request.post('/crm/crm-vip-integral-exchange-record/query-page-list', params) //会员积分兑换记录
export const crmVipGetWxQrcode = params => request.post('/crm/vip/get-wx-qrcode', params) //会员小程序二维码
export const querySceneTypeList = params => request.post('/crm/vip/query-scene-type-list', params) //积分兑换明细类型列表
